@import 'variables';

.alert() {
  padding: 1rem;
  border: none;
  border-radius: @radius-default;
  background-color: @color-default-1;

  a:not(.btn) {
    text-decoration: underline;
  }
}

each(@color-variants, {
  @bg-color: 'color-@{value}-1';
  @text-color: 'color-@{value}-5';
  .alert-@{value} {
    .alert();
    background-color: @@bg-color;
    color: if((@value = default), @color-default-text-5, @@text-color);
  }
})