@import "../_variables";

#modal-prime {

  .cr-modal-body {
    padding: 10px;
  }

  .be-prime {
    background: #1e618b;
    padding: 25px 20px;
    margin: 0;
  }

  .close-modal {
    color: #fff;
    font-size: 18px;
    line-height: 37px;
  }

  .close-modal:hover {
    color: #fff1ed;
  }

  .description-prime {
    margin-top: 10px;
    font-size: 15px;
  }
}

@media (max-width: 790px) {
  #modal-prime .be-prime {
    margin-right: 3px;
  }
}

@media (max-width: 380px) {
  #modal-prime .be-prime {
    margin-right: 0;
  }
}