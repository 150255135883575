@import 'variables';

.badge() {
  display: inline-flex;
  align-items: baseline;
  padding: .25em .5em;
  color: white;
  font-size: .75em;
  border-radius: @radius-default;
  white-space: nowrap;
  border: 1px solid transparent;
}

.pill() {
  display: inline-flex;
  align-items: baseline;
  padding: .25em .75em;
  color: white;
  font-size: .65em;
  border-radius: 2em;
  line-height: 1;
  white-space: nowrap;
  border: 1px solid transparent;
}

.badge-white {
  .badge();
  color: @color-default-4;
  background-color: white;
}

.pill-white {
  .pill();
  color: @color-default-4;
  background-color: white;
}

.badge-pill-levels(@variant) {
  each(@color-levels, {
    @color: 'color-@{variant}-@{value}';

    .badge-@{variant}-@{value} {
      .badge();
      background-color: @@color;
    }

    .badge-outline-@{variant}-@{value} {
      .badge();
      color: @@color;
      border-color: @@color;
    }

    .pill-@{variant}-@{value} {
      .pill();
      background-color: @@color;
    }

    .pill-outline-@{variant}-@{value} {
      .pill();
      color: @@color;
      border-color: @@color;
    }

    .badge-@{variant}-@{value}, .pill-@{variant}-@{value} {
      & when (@value < 3) {
        color: @color-default-text-5;
      }
    }
  })
}

.badge-pill(@value) {
  @color: 'color-@{value}-4';

  .badge-@{value} {
    .badge();
    background-color: @@color;
  }

  .badge-outline-@{value} {
    .badge();
    color: @@color;
    border-color: @@color;
  }

  .pill-@{value} {
    .pill();
    background-color: @@color;
  }

  .pill-outline-@{value} {
    .pill();
    color: @@color;
    border-color: @@color;
  }

  .badge-pill-levels(@value);
}

each(@color-variants, {
  .badge-pill(@value);
});

.badge-group, .pill-group {
  display: inline-flex;

  [class*='badge-']:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  [class*='badge-']:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: transparent;
  }
}