@import '../../../submodules/src/styles/variables';
@import '../../../submodules/src/styles/mixins';
@import '../_variables';

// <editor-fold desc="Text">

// todo change name
.text-yanone() {
    font-family: @roboto;
}

// </editor-fold>
// <editor-fold desc="Footer">

.compact-footer() {
    #footer-main-content > * {
        flex: 1 1 0 !important;
    }

    .compact-footer-hidden {
        display: none !important;
    }

    #footer-advantages {
        flex-grow: 2 !important;
    }

    .footer-advantages-items {
        max-width: none !important;
        display: flex;
        flex-wrap: wrap;

        > .d-flex {
            flex: 0 0 48% !important;
            margin-top: 0 !important;
            margin-bottom: 1rem;

            &:nth-child(2n) {
                margin-right: 0.5rem;
            }
        }
    }
}

// </editor-fold>
// <editor-fold desc="Slider">

.slider-spacing(@space: @default-slider-horizontal-spacing) {
    margin-left: -@space;
    margin-right: -@space;

    &:not(.slider-ready) > * {
        padding-left: @space;
        padding-right: @space;
    }

    .slide {
        padding-left: @space;
        padding-right: @space;
    }

    @media (@min-screen-xs) {
        margin-left: -@space * 2;
        margin-right: -@space * 2;

        &:not(.slider-ready) > * {
            padding-left: @space * 2;
            padding-right: @space * 2;
        }

        .slide {
            padding-left: @space * 2;
            padding-right: @space * 2;
        }
    }
}

// </editor-fold>
// <editor-fold desc="Icons">

.icon-overlay() {
    position: absolute;
    opacity: 0.75;
    background-color: white;
    border-radius: 0.2rem;
}

.icon-overlay-top(@spacing) {
    top: @spacing;
}

.icon-overlay-bottom(@spacing) {
    bottom: @spacing;
}

.icon-overlay-right(@spacing) {
    right: @spacing;
}

.icon-overlay-left(@spacing) {
    left: @spacing;
}

.icon-overlay-top-right(@spacing) {
    .icon-overlay();
    .icon-overlay-top(@spacing);
    .icon-overlay-right(@spacing);
}

.icon-overlay-top-left(@spacing) {
    .icon-overlay();
    .icon-overlay-top(@spacing);
    .icon-overlay-left(@spacing);
}

.icon-overlay-bottom-right(@spacing) {
    .icon-overlay();
    .icon-overlay-bottom(@spacing);
    .icon-overlay-right(@spacing);
}

// </editor-fold>

// region Scroll

.scroll-momentum() {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; // iOS smooth scroll / momentum
}

// endregion

// <editor-fold desc="Drawers">

.base-overlay() {
    pointer-events: none;
    background-color: @overlay-color;
    opacity: 0;
    transition: opacity @drawer-transition-duration @transition-curve;

    &.show {
        pointer-events: initial;
        opacity: @overlay-opacity;
    }

    h2 {
        align-self: flex-start;
    }
}

// </editor-fold>

.font-styles(@size, @weight, @letter-spacing) {
    font-size: @size;
    font-weight: @weight;
    letter-spacing: @letter-spacing;
}

.flex-row(@gap: 0, @justify-content: flex-start, @align-items: flex-start) {
    display: flex;
    gap: @gap;
    justify-content: @justify-content;
    align-items: @align-items;
}

.flex-col(@gap: 0, @justify-content: flex-start, @align-items: flex-start) {
    display: flex;
    flex-direction: column;
    gap: @gap;
    justify-content: @justify-content;
    align-items: @align-items;
}
