@import 'variables';
@import 'mixins';

// <editor-fold desc="Tables">

table {
  width: 100%;
  border-collapse: collapse;

  td, th {
    padding: .5rem;
    text-align: left;
  }

  thead {
    background-color: @color-default-1;
  }

  th, tfoot td {
    color: @color-default-text-4;
    font-weight: @bold-weight;
  }

  th {
    border-bottom: solid 2px @color-default-4;
  }

  td {
    border-bottom: solid 1px @color-default-2;
    vertical-align: top;
  }

  tbody tr {
    background-color: @color-table-row-1;
  }

  @media (hover: hover) and (pointer: fine) {
    &.table-hover tbody tr:hover {
      background-color: @color-table-row-hover;
    }
  }

  @vertical-alignments: baseline, top, middle, bottom;
  each(@vertical-alignments, {
    &.align-@{value} td,
    td.align-@{value} {
      vertical-align: @value;
    }
  })
}

table.table-dotted {

  tbody tr {
    background-color: transparent;
  }

  th, tbody tr td {
    border-bottom: none;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to right, @color-default-4 20%, transparent 0%);
      background-position: bottom;
      background-size: 5px 1px;
      background-repeat: repeat-x;
      height: 1px;
    }
  }
}

table.table-striped {
  tfoot tr {
    background-color: @color-default-2;
    color: white;
  }

  tbody tr:nth-child(2n + 1) {
    background-color: @color-table-row-2;
  }

  @media (hover: hover) and (pointer: fine) {
    &.table-hover tbody tr:nth-child(2n + 1):hover {
      background-color: @color-table-row-hover;
    }
  }
}

table.expandable-table {
  width: 100%;

  > tbody {
    > tr {
      border: 2px solid @color-default-2;
    }

    > tr:nth-child(4n + 1) {
      background-color: @color-default-1;
    }

    > tr:nth-child(4n + 3) {
      background-color: @color-default-2;
    }

    > td {
      line-height: 2.5em;
    }
  }
}

table.data-table {
  th {
    position: relative;

    &.has-filter {
      padding-right: 3rem !important;
    }

    .data-table-filter {
      .paper(@color-default-2);
      position: absolute;
      display: none;
      top: 50%;
      left: 2px;
      right: 2px;
      transform: translateY(-50%);
      border-radius: @radius-default;
      box-shadow: @shadow-1;
      padding: 0.25rem 3rem 0.25rem 0.25rem;
      z-index: 1;

      &.show {
        display: block;
      }
    }

    .fa-filter {
      position: absolute;
      top: 50%;
      right: 0;
      width: 3rem;
      text-align: center;
      transform: translateY(-50%);
      z-index: 2;

      &.filtered {
        color: @color-primary-4;
      }

      &:hover {
        color: @color-primary-2;
      }
    }
  }

  thead {
    user-select: none;
  }

  thead tr th span.sortable {
    cursor: pointer;

    &.sorted {
      color: @color-primary-4;
    }

    &:hover {
      color: @color-primary-2;
    }
  }
}

// </editor-fold>