@import '../../submodules/src/styles/alert';
@import '../../submodules/src/styles/badge-pill';
@import '../../submodules/src/styles/tables';
@import '_variables';
@import 'element/_icons-mixins';
@import 'element/_mixins';
@import 'element/_modal-prime';

.icon-mug();
.icon-heart();
.icon-ok();
.icon-tag();

#cart {
  @media (max-width: 513px) {
    flex-direction: column-reverse;
  }
}

#footer {
  .compact-footer();
  display: none;
}

#cart,
#cart-gift-options,
#cart-donation,
#cart-special-mug,
#cart-organization-donation {
  transition: opacity @transition-duration @transition-curve;

  &.loading {
    opacity: 0.5;
    pointer-events: none;
  }
}

#cart-recommendations ul {
  align-items: start;

  > li {
    background-color: @color-default-1;
    border-radius: @radius-default;
    overflow: hidden;

    img {
      border: 1px solid @color-default-2;
      border-top-left-radius: calc(@radius-default + 0.1rem);
      border-bottom-left-radius: calc(@radius-default + 0.1rem);
    }
  }
}

#cart-gift-options {
  #cart-gift-options-loading {
    min-height: 150px;
    padding-top: 60px;

    > div {
      &:extend(.cr-spinner);
      .spinner-size(2rem, white);
    }
  }

  #cart-gift-options-content {
    position: relative;

    #cart-gift-option-zoomed {
      position: absolute;
      background-color: @color-default-1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;

      div {
        margin-top: 3rem;
        opacity: 0;
        transition: opacity @transition-duration @transition-curve;
      }

      img.loaded + div {
        opacity: 1;
      }
    }

  }

  #cart-extra-packing {
    position: relative;

    img {
      border: 2px solid transparent;
    }

    &.selected img {
      border-color: @color-accent-1;
    }
  }

  #cart-gift-options-list > li {
    position: relative;
    flex: 0 0 50%;
  }

  .cart-gift-option-check {
    .icon-overlay-top-left(1rem);
    opacity: 0;
    transition: opacity @transition-duration @transition-curve;
  }

  .cart-gift-option-image {
    position: relative;

    .cart-gift-option-zoom {
      .icon-overlay-bottom-right(1rem);
      opacity: 0;
      transition: opacity @transition-duration @transition-curve;

      .icon-cr {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    img {
      border: 2px solid transparent;

      &.loaded {
        // só exibe após lazy load

        + .cart-gift-option-zoom {
          opacity: 0.75;
        }
      }
    }
  }

  .selected {
    .cart-gift-option-check {
      opacity: 0.75;
    }

    img {
      border-color: @color-accent-1;
    }

    &.cart-gift-option-image .product-list-item-title {
      color: @color-accent-1;
    }
  }
}

#cart-empty {
  .flex-grid {
    margin-bottom: 0;
  }

  #cart-recommendations h3 {
    text-align: center;
  }

  .product-list {
    margin-bottom: 0;
  }
}

#cart-products {
  .alert-danger,
  .alert-success {
    margin-bottom: 1rem;
  }

  .cart-products-banner {
    border-radius: @radius-default;
    overflow: hidden;

    img {
      width: 100%;

      @media (min-width: @max-content-width) {
        object-fit: none;
      }
    }
  }
}

.cart-review-loading {
  &:extend(.cr-spinner);
  .spinner-size(1.5rem, white);
}

#cart-shipping,
#cart-discount {
  align-items: baseline;
}

#cart-shipping-table {
  th,
  td {
    text-align: left;
  }

  tbody tr {
    background-color: transparent;
  }

  thead tr {
    background-color: transparent;
  }

  @media (@max-screen-sm) {
    td:first-child {
      width: 28%;
    }

    td:last-child {
      width: 28%;
    }
  }

  @media (@min-screen-sm) {
    td:first-child {
      width: 24%;
    }
  }

  th:last-child,
  td:last-child {
    text-align: right;
  }

  label {
    margin: 0;
  }

  .cart-shipping-table-img {
    width: 3.5rem;
    height: 1.4rem;
  }
}

#cart-shipping-express.d-flex {
  align-items: center;
  background-color: @color-default-3;
  padding: 1rem;
  border-radius: @radius-default;

  h4 {
    color: white;
    margin: 0 1rem 0 0;
  }
}

#my-wrapper {
  margin-bottom: 11rem;
}

#cart-empty #cart-recommendations ul {
  @media (@min-screen-sm) {
    display: flex;
    flex-wrap: wrap;

    > li {
      flex: 1 0 40%;
      max-width: calc(50% - 0.5rem);
      margin-left: 1rem;

      @media (@max-screen-md) {
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }
    }
  }

  @media (@min-screen-md) {
    > li {
      flex: 1 0 26%;
      max-width: calc(33% - 0.5rem);

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }
  }
}

@media (@min-screen-xs) {
  #my-wrapper {
    margin-bottom: 0;
  }

  #footer {
    display: block;
  }
}

@media (@min-screen-sm) {
  #cart-gift-options {
    #cart-gift-options-content #cart-gift-option-zoomed {
      display: flex;

      img {
        height: 100%;
        margin-right: 5rem;
      }
    }

    #cart-gift-options-list > li {
      flex: 0 0 25%;
    }
  }
}

@media (@min-screen-md) {
  #cart-gift-options {
    #cart-gift-options-list > li {
      flex: 0 0 12.5%;
    }
  }

  #cart #cart-recommendations ul {
    display: flex;
    flex-wrap: wrap;

    > li {
      flex: 1 0 40%;
      max-width: calc(50% - 0.5rem);
      margin-left: 1rem;

      &:nth-child(2n + 1) {
        margin-left: 0;
      }
    }
  }
}

.values {
  margin: 20px 1.5rem 1.5rem 20px;

  @media (max-width: 513px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.coupon-button {
  background-color: white;
  color: black;
  font-size: 13px;
  border: 1px solid;
  border-radius: unset;

  @media (max-width: 513px) {
    width: 50% !important;
  }
}

.cart-mobile-flex {
  @media (max-width: 513px) {
    display: flex;
  }
}

.gift-button-div {
  @media (@min-screen-md) {
    padding: 0 8rem;
  }
  @media (@max-screen-sm) {
    padding: 0 8rem;
  }
}

#cart-special-options {
  #cart-special-options-loading {
    min-height: 150px;
    padding-top: 60px;

    > div {
      &:extend(.cr-spinner);
      .spinner-size(2rem, white);
    }
  }

  #cart-special-options-content {
    position: relative;
  }

  #cart-special-options-list > li {
    position: relative;
    flex: 0 0 50%;
  }

  .cart-special-options-image {
    position: relative;
    display: flex !important;

    .cart-gift-option-zoom {
      .icon-overlay-bottom-right(1rem);
      opacity: 0;
      transition: opacity @transition-duration @transition-curve;

      .icon-cr {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    img {
      border: 2px solid transparent;

      &.loaded {
        // só exibe após lazy load

        + .cart-gift-option-zoom {
          opacity: 0.75;
        }
      }
    }
  }
}
